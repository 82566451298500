import React, { useState } from "react";
import { Row, Col, Container } from "react-bootstrap";
import Image from "next/image";
import Accordion from "react-bootstrap/Accordion";
import parseHtml from "utils/parseHtml";
// import imds from "../../../public/baskan-img/baskan hakkında.png";
import imds from "../../../public/baskan-img/haber2.jpg";

const Hakkimda = ({ data }) => {
  const list1 = data?.sections[0]?.categories[0]?.contents;
  const [event, setEvent] = useState(1);
  return (
    <div id="baskan-hakkinda">
      <div className="baskan-hakkinda">
        <h1>HAKKIMDA</h1>
        <div>
          <div className="head_line">
            <span></span>
          </div>
        </div>

        <Container>
          <Row className="baskan-hakkinda-satir">
            <Col md={6} xs={12}>
              <span className="before"></span>
              <Image
                style={{ zIndex: "50" }}
                src={imds}
                layout="responsive"
                width={190}
                height={130}
                quality={40}
                className="image"
              />
            </Col>
            <Col md={6} xs={12} className="right-col">
              <span className="after"></span>
              <div className="salih-ekinci-bilgileri">
                {/* <div className="salih-ekinci">
                  <p>SALİH EKİNCİ</p>
                  <span></span>
                </div> */}
                <div className="salih-ekinci-btn">
                  <Accordion defaultActiveKey="0">
                    {list1?.map((item, i) => (
                      <Accordion.Item
                        eventKey={`${i}`}
                        key={i}
                        style={{ marginBottom: "10px" }}
                      >
                        <Accordion.Header>{item.title} </Accordion.Header>
                        <Accordion.Body>
                          {parseHtml(item.details)}
                        </Accordion.Body>
                      </Accordion.Item>
                    ))}
                  </Accordion>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default Hakkimda;
